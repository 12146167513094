import React, { useState, useEffect, Fragment } from 'react';
import { Map3D } from '@yourhere/yourhere-core'
import { Config, SceneWrapper } from '@yourhere/yourhere-3dmapping'
import generateTable from './generateTable.js'
import './App.scss';
import config from './brentwood-config.json'
import './fonts/ACaslonPro-Bold.otf'
import './fonts/Mark-Heavy.ttf'
import './fonts/Mark-Medium.ttf'

function App() {
  const [activeFloor, setActiveFloor] = useState('L1')
  const [activeTenant, setActiveTenant] = useState('')
  const [lookup, setLookup] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [cmsData, setCmsData] = useState()
  const [showList, setShowList] = useState(false)
  const [viewMode, setViewMode] = useState('full')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const changeTenant = (suitenum) => {
    const matchingTenant = cmsData.tenants.find(t => `${t.suite.textfields.suitenum}`.toLowerCase() === `${suitenum}`.toLowerCase())

    if (!matchingTenant) {
      return
    }

    const floorValue = `${matchingTenant.floor.name}`

    setActiveFloor(floorValue)
    setActiveTenant(`${suitenum}`)

    if (isMobile) {
      setSearchTerm(matchingTenant.name)
    }
  }

  const changeFloor = (floor) => {
    setShowList(false)
    setActiveTenant('') 
    setActiveFloor(floor)
  }

  const focusSearch = () => {
    setShowList(true)
  }

  useEffect(() => {
    const getTenantData = async () => {
      const baseURL = 'https://cms-v2.yourhere.ca'
      const accountid = '62e014d1259243293c7f3b27'
      const key = 'P0+ZevdFWnIpGdHgTMFBFcGH'
      // const baseURL = 'http://localhost:80'
      // const accountid = '63c1f047cfa7ac4c7420a5cc'
      // const key = 'LUvQmb3L4I7kouO0HZyxV+Vy'
    
      const cmsRes = await fetch(`${baseURL}/v1/mapdata/tenants/${accountid}`, {
        method: 'GET',
        headers: {
          'yrh-integration-api-key': key
        }
      })
    
      const json = await cmsRes.json()

      if (json) {
        setCmsData(json)
      }

      const tenantTable = await generateTable(json)

      // tenantTable[206] = '69737'

      setLookup(tenantTable)

      const defaultTenant = new URLSearchParams(window.location.search).get('t')

      const matchingTenant = defaultTenant && tenantTable[`${defaultTenant}`] && json.tenants.find(t => `${t._id}` === `${tenantTable[`${defaultTenant}`]}`)

      if (matchingTenant) {
        setViewMode('simple')
        setActiveFloor(`${matchingTenant.floor.name}`)
        setActiveTenant(`${matchingTenant.suite.textfields.suitenum}`)
      } else {
        setActiveFloor('L1')
      }
    }

    if (!cmsData) {
      getTenantData()
    }
  }, [cmsData])

  useEffect(() => {
    const e = window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 768)
    })

    return () => {
      window.removeEventListener('resize', e)
    }
  }, [])

  if (!cmsData || !activeFloor || !lookup) {
    return null
  }

  if (new URLSearchParams(window.location.search).get('t') && !activeTenant) {
    return null
  }

  const list = cmsData.tenants
    .filter(t => !searchTerm || t.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .reduce((acc, curr) => {
      const {
        category
      } = curr

      const c = category[0] || '_general'

      if (!acc[c]) {
        acc[c] = []
      }

      acc[c].push(curr)

      return acc
    }, {})

  const clickHandler = (mesh) => {
    if (viewMode === 'simple') {
      return
    }

    const {
      name
    } = mesh

    const configData = config.shops.find(s => s.assetId === name)

    if (!configData || !configData.cmsID) {
      return
    }

    changeTenant(configData.cmsID)

    if (isMobile) {
      const matchingTenant = cmsData.tenants.find(t => `${t.suite.textfields.suitenum}`.toLowerCase() === `${configData.cmsID}`.toLowerCase())
      if (!matchingTenant) {
        return
      }

      setShowList(true)
      setSearchTerm(matchingTenant.name)
    }

    const listElement = document.querySelector('.tenant-button.active')
    
    if (!listElement) {
      return
    }

    listElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const icons = {
    information: './icons/amenities/concierge.svg',
    elevator: './icons/amenities/elevator.svg',
    escalator: './icons/amenities/escalator.svg',
    skytrain: './icons/amenities/skytrain.svg',
    stairs: './icons/amenities/stairs.svg',
    bathroom: './icons/amenities/washrooms.svg'
  }

  cmsData.categories.push({
    label_id: '_general',
    name: 'General'
  })

  return (
    <div className={`App ${viewMode}`}>
      <div className="sidebar">
        <h1>Map</h1>
        <div className="floor-section">
          <h2 className="subheading">View Floor</h2>
          <select value={activeFloor} onChange={(e) => changeFloor(e.target.value)}>
            <option value="L3">Level 3</option>
            <option value="L2">Level 2</option>
            <option value="L1">Level 1</option>
            <option value="P1">P1</option>
            <option value="P2">P2</option>
            <option value="P3">P3</option>
            <option value="P4">P4</option>
          </select>
        </div>
        <div className="search-section">
          <h2 className="subheading">Shop</h2>
          <div className="search-wrapper">
            <input
              type="text"
              value={searchTerm}
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={focusSearch}
              className="shop-search"
            />
          </div>
        </div>
        <div className={`tenant-list ${searchTerm ? 'hide-categories' : ''}`}>
          <button
            className={`tenant-list-button ${showList ? 'up' : 'down'}`}
            onClick={() => setShowList(!showList)}
          >
            {searchTerm ? 'Tenants' : 'Categories'}
          </button>
          <div className={`tenant-list-wrap ${showList ? 'show' : 'hide'}`}>
            {
              Object.entries(list).sort(([idA], [idB]) => idA.localeCompare(idB)).map(([id, tenants]) => (
                <React.Fragment key={id}>
                  <h3 className="category-heading subheading">{cmsData.categories.find(c => c.label_id === id).name}</h3>
                  <div className="category-listings">
                    {
                      tenants
                        .filter(t => t.suite && t.floor)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(t => {
                          const {
                            suite: {
                              textfields: {
                                suitenum = ''
                              } = {}
                            } = {},
                            floor: {
                              name: floornum = ''
                            } = {},
                            name,
                            _id
                          } = t

                          const brentwoodData = lookup[`cms-${_id}`]

                          return (
                            <Fragment key={`${suitenum}-${floornum}-${name}`}>
                              <button
                                className={`tenant-button ${`${activeTenant}` === `${suitenum}` ? 'active' : ''}`}
                                onClick={() => changeTenant(suitenum)}
                              >
                                {name}
                              </button>
                              {`${activeTenant}` === `${suitenum}` && <div className='read-more'>
                                <div>Floor: {floornum.replace('L', 'Level ')}</div>
                                {brentwoodData ? <a target='_blank' rel='noopener noreferrer' href={brentwoodData.link}>Read more ↗</a> : null}
                              </div>}
                            </Fragment>
                          )
                        })
                    }
                  </div>
                </React.Fragment>
              ))
            }
          </div>
        </div>
      </div>
      {isMobile ? null : <div className="helpIcon">
        <img
          src="./icons/rotation.png"
        />
      </div>}
      <Map3D
        babylonFilepath='/models/brentwood.babylon'
        mapping={{
          config: Config,
          sceneWrapper: SceneWrapper
        }}
        config={config}
        tenantData={cmsData.tenants}
        icons={icons}
        options={{
          hideControls: true,
          hideNorthMarker: false,
          showRouteSelector: false,
          clickToHighlight: viewMode === 'full',
          centerActiveTenant: true,
          orthoScale: 0.05,
          upperZoomLimit: isMobile ? 0.15 : 0.08,
          lowerZoomLimit: 0.01,
          cameraBeta: isMobile ? 0 : Math.PI / 4,
          disableRotationX: isMobile,
          disableRotationY: isMobile,
          maxRotation: Math.PI / 4,
          minRotation: Math.PI / -4,
          labelKey: 'mapname',
          labelFont: 'Mark',
          maxTextLabelSize: 0.015,
          northMarker: './icons/north.svg',
          clickHandler
        }}
        activeFloor={activeFloor}
        activeTenant={activeTenant}
        activeAmenities={['skytrain', 'bathroom', 'information', 'stairs', 'elevator', 'escalator']}
        primaryHighlight="#ed3803"
        backgroundColor='#555555'
      />
      <div className='legend'>
        <img src='./legend.svg' />
      </div>
    </div>
  );
}

export default App;
