"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _pathRenderer = _interopRequireDefault(require("./pathRenderer.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Pathfinding = function Pathfinding(sceneWrapper, meshList, markerToRenderLoop) {
  var _this = this;

  _classCallCheck(this, Pathfinding);

  this.getTransfers = function (assets, allNodes) {
    var transferOptions = _this.accessibilityOnly ? ['elevator', 'ramp'] : ['elevator', 'escalator', 'stairs', 'ramp'];
    var transferNodes = assets.filter(function (asset) {
      return asset.assetType && asset.assetType.some(function (t) {
        return transferOptions.includes(t);
      });
    }).map(function (asset) {
      var linkedNode = Pathfinding.getNodeLinkedToAsset(asset.assetId, allNodes);

      if (linkedNode && !linkedNode.targetNodes.includes(asset.assetId)) {
        linkedNode.targetNodes.push(asset.assetId);
      }

      return {
        nodeId: asset.assetId,
        position: asset.position,
        targetNodes: [].concat(_toConsumableArray(asset.transferFloors), [linkedNode && linkedNode.nodeId]),
        targetAssets: [],
        nodeType: 'transfer',
        assetType: asset.assetType || []
      };
    });
    return transferNodes;
  };

  this.findLinkedTransfer = function (unchartedFloor) {
    var transferOptions = _this.accessibilityOnly ? ['elevator', 'ramp'] : ['elevator', 'escalator', 'stairs', 'ramp'];

    var transferNodes = _this.scene.assets.filter(function (asset) {
      return asset.assetType && asset.assetType.some(function (t) {
        return transferOptions.includes(t);
      });
    });

    var linkedTransfer = transferNodes.find(function (transfer) {
      var unchartedFloors = transfer.uncharted && transfer.uncharted.split(',').map(function (u) {
        return u.trim();
      }).filter(function (u) {
        return u;
      });
      return unchartedFloors && unchartedFloors.includes("".concat(unchartedFloor));
    });
    return linkedTransfer;
  };

  this.createUnchartedDestination = function (destination) {
    var linkedTransfer = _this.findLinkedTransfer(destination.unchartedFloor);

    if (!linkedTransfer) {
      console.log('No valid transfers to uncharted room');
      return false;
    }

    var position = linkedTransfer.position,
        assetType = linkedTransfer.assetType,
        assetId = linkedTransfer.assetId;
    var linkedNode = Pathfinding.getNodeLinkedToAsset(assetId, _this.scene.allNodes);

    if (!linkedNode.targetAssets.includes(destination.assetId)) {
      linkedNode.targetAssets.push(destination.assetId);
    }

    destination.position = position;
    return {
      nodeId: assetId,
      displayName: "Take ".concat(assetType, " to Floor ").concat(destination.unchartedFloor),
      nodeType: 'destination',
      position: position,
      distance: Infinity
    };
  };

  this.createOriginNode = function (startNode, allNodes) {
    var nodeLinkedToOrigin = Pathfinding.getNodeLinkedToAsset(startNode, allNodes);
    var allNodeFound = allNodes.find(function (node) {
      return node.nodeId === startNode;
    });
    var originPosition = allNodeFound && allNodeFound.position || nodeLinkedToOrigin && nodeLinkedToOrigin.position;

    var filteredNodes = _toConsumableArray(allNodes);

    if (allNodeFound) {
      filteredNodes = filteredNodes.filter(function (n) {
        return n !== allNodeFound;
      });
    }

    if (!nodeLinkedToOrigin) {
      if (!allNodeFound) {
        return {
          originNode: null,
          filteredNodes: filteredNodes
        };
      }

      return {
        originNode: {
          nodeId: startNode,
          nodeType: 'origin',
          position: originPosition,
          targetNodes: allNodeFound.targetNodes,
          targetAssets: allNodeFound.targetAssets,
          visited: false,
          distance: 0
        },
        filteredNodes: filteredNodes
      };
    }

    return {
      originNode: {
        nodeId: startNode,
        nodeType: 'origin',
        position: originPosition,
        targetNodes: [nodeLinkedToOrigin.nodeId],
        visited: false,
        distance: 0
      },
      filteredNodes: filteredNodes
    };
  };

  this.createDestinationNode = function (destName, allNodes, assets) {
    var destinationAsset = assets.find(function (a) {
      return destName === a.assetId;
    });
    var destinationNode = allNodes.find(function (n) {
      return destName === n.nodeId;
    });
    var destination = destinationAsset || destinationNode || {};

    if (destination.isUncharted) {
      return _this.createUnchartedDestination(destination);
    }

    return {
      nodeId: destName,
      displayName: destinationAsset ? destinationAsset.displayName || destinationAsset.cms && destinationAsset.cms[_this.sceneWrapper.labelKey] : destName,
      nodeType: 'destination',
      position: destination.position,
      distance: Infinity
    };
  };

  this.djikstra = function (startNode, endNode) {
    var scene = _this.scene;

    var transferNodes = _this.getTransfers(_this.scene.assets, _this.scene.allNodes);

    var allNodes = [].concat(_toConsumableArray(_this.scene.allNodes), _toConsumableArray(transferNodes)).filter(function (n) {
      var _n$assetType = n.assetType,
          assetType = _n$assetType === void 0 ? [] : _n$assetType;

      if (assetType.includes('accessOnly')) {
        return _this.accessibilityOnly;
      }

      if (assetType.includes('nonAccessOnly')) {
        return !_this.accessibilityOnly;
      }

      return true;
    });
    allNodes.forEach(function (node) {
      node.distance = Infinity;
      node.visited = false;
    });

    var _this$createOriginNod = _this.createOriginNode(startNode, allNodes),
        originNode = _this$createOriginNod.originNode,
        filteredNodes = _this$createOriginNod.filteredNodes;

    allNodes = filteredNodes;

    var destinationNode = _this.createDestinationNode(endNode, allNodes, _this.scene.assets);

    if (!originNode || !destinationNode) {
      return {
        success: false,
        message: 'Unlinked assets'
      };
    }

    var currentNode = originNode;
    var potentialNodes = [];

    var _loop = function _loop() {
      currentNode.visited = true;
      var targetAssets = currentNode.targetAssets && currentNode.targetAssets.filter(function (asset) {
        return scene.assets.find(function (a) {
          return a.assetId === asset;
        });
      }).map(function (asset) {
        return {
          nodeId: asset,
          distance: Infinity,
          visited: false,
          targetNodes: [],
          position: scene.assets.find(function (a) {
            return a.assetId === asset;
          }).position
        };
      });
      var assetNodes = targetAssets ? _toConsumableArray(targetAssets) : [];
      var targets = [].concat(_toConsumableArray(Pathfinding.getPotentialNodes(currentNode, allNodes)), _toConsumableArray(assetNodes));
      var _currentNode = currentNode,
          distance = _currentNode.distance,
          nodeId = _currentNode.nodeId;
      targets.forEach(function (targetNode) {
        var targetId = targetNode.nodeId;
        var targetObj = scene.getMeshByName(targetId) || scene.getNodeByName(targetId);
        var currentObj = scene.getMeshByName(nodeId) || scene.getNodeByName(nodeId);
        var targetPosition = targetObj.getAbsolutePosition();
        var currentPosition = currentObj.getAbsolutePosition();
        var pathDistance = Pathfinding.distanceBetweenCoords(currentPosition, targetPosition) + distance;

        if (pathDistance < targetNode.distance) {
          targetNode.distance = pathDistance;

          if (!targetNode.visited) {
            targetNode.bestParent = nodeId;
          }
        }
      });
      potentialNodes.push.apply(potentialNodes, _toConsumableArray(targets));
      potentialNodes.sort(function (a, b) {
        return b.distance - a.distance;
      });
      var bestNode = potentialNodes.filter(function (n) {
        return !n.visited;
      }).pop();

      if (!bestNode) {
        return {
          v: {
            success: false,
            message: 'Path not found'
          }
        };
      }

      currentNode = bestNode;
    };

    while (currentNode.nodeId !== destinationNode.nodeId) {
      var _ret = _loop();

      if (_typeof(_ret) === "object") return _ret.v;
    }

    destinationNode.bestParent = currentNode.bestParent;
    return {
      path: Pathfinding.getPath(destinationNode, [].concat(_toConsumableArray(allNodes), [originNode])),
      success: true
    };
  };

  this.scene = sceneWrapper.scene;
  this.sceneWrapper = sceneWrapper;
  this.accessibilityOnly = false;
  this.pathRenderer = new _pathRenderer["default"](sceneWrapper, meshList, markerToRenderLoop);
};

exports["default"] = Pathfinding;

Pathfinding.getNodeLinkedToAsset = function (assetName, nodes) {
  // Find the node that is connected to the room
  var foundNode = nodes.find(function (node) {
    return node.targetAssets && node.targetAssets.includes(assetName);
  });
  return foundNode || null;
};

Pathfinding.getPotentialNodes = function (node, allNodes) {
  return allNodes.filter(function (n) {
    return n.nodeId !== node.nodeId && !n.visited && node.targetNodes.includes(n.nodeId);
  });
};

Pathfinding.distanceBetweenCoords = function (a, b) {
  return Math.pow(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2) + Math.pow(b.z - a.z, 2), 0.5);
};

Pathfinding.getPath = function (node, allNodes) {
  var path = [node];
  var currentNode = node;

  var _loop2 = function _loop2() {
    var _currentNode2 = currentNode,
        bestParent = _currentNode2.bestParent;
    var parentNode = allNodes.find(function (n) {
      return n.nodeId === bestParent;
    });
    path.push(parentNode);
    currentNode = parentNode;
  };

  while (currentNode && currentNode.bestParent) {
    _loop2();
  }

  return path.reverse();
};