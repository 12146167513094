"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Config", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
Object.defineProperty(exports, "SceneWrapper", {
  enumerable: true,
  get: function get() {
    return _sceneWrapper["default"];
  }
});

require("@babel/polyfill");

require("@babel/register");

var _config = _interopRequireDefault(require("./helpers/config.js"));

var _sceneWrapper = _interopRequireDefault(require("./helpers/sceneWrapper.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }