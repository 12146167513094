"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _getFloorFromName = _interopRequireDefault(require("./getFloorFromName.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PathRenderer = function PathRenderer(_sceneWrapper, _meshList, markerToRenderLoop) {
  var _this = this;

  _classCallCheck(this, PathRenderer);

  this.renderPath = function (path) {
    return new Promise(function (resolve) {
      var origin = path.find(function (node) {
        return node.nodeType === 'origin';
      });
      var destination = path.find(function (node) {
        return node.nodeType === 'destination';
      });

      _this.sceneWrapper.log('Rendering Path', _toConsumableArray(path)); // Create pairs of 'in' and 'out' transfers


      var transferPairs = path.filter(function (node) {
        return node.nodeType === 'transfer';
      }).reduce(function (acc, node, idx, nodes) {
        if (idx % 2 === 0) {
          acc.push(nodes.slice(idx, idx + 2));
        }

        return acc;
      }, []);
      var subPaths = []; // Split the original path based on transfers

      while (path.length > 0) {
        var foundTransfer = path.slice(1).findIndex(function (node) {
          return node.nodeType === 'transfer';
        });
        var cutIdx = foundTransfer > 0 ? foundTransfer + 2 : path.length;
        subPaths.push(path.splice(0, cutIdx));
      } // Lower all nodes to the floor rather than the center of the room


      subPaths.forEach(function (subPath) {
        var pathFloor = subPath.find(function (n) {
          return n.floor;
        }).floor;

        var pathFloorMesh = _this.scene.getMeshByName(pathFloor);

        var lowestY = Math.min.apply(Math, _toConsumableArray(subPath.map(function (node) {
          return node.position.y + pathFloorMesh.position.y;
        })));
        subPath.forEach(function (node) {
          // eslint-disable-next-line no-param-reassign
          node.pathY = lowestY;
        });
      });
      var finalTransferPair = transferPairs[transferPairs.length - 1];

      if (finalTransferPair && finalTransferPair.length < 2) {
        finalTransferPair.push(destination);
      }

      var imageBase = _this.sceneWrapper.mode === 'production' ? '../../public/icons' : './images'; // Animate each subpath to skip transfers

      Promise.all(subPaths.map(function (subPath) {
        return _this.animatePath(subPath);
      })).then(function () {
        _this.flatFlyout({
          id: origin.nodeId,
          image: "".concat(imageBase, "/yrh-marker-top.png"),
          position: origin.position,
          type: 'origin',
          node: origin
        });

        _this.flatFlyout({
          id: destination.nodeId,
          text: destination.text || destination.displayName || destination.nodeId,
          position: destination.position,
          type: 'destination',
          node: destination
        });

        transferPairs.forEach(function (pair) {
          var floorName = _this.scene.floors.find(function (f) {
            return f.name === (0, _getFloorFromName["default"])(pair[1].nodeId);
          });

          var type1 = PathRenderer.getTransferType(pair[0].assetType);
          var type2 = PathRenderer.getTransferType(pair[1].assetType);
          var textIn = "Take ".concat(type1, " to ").concat(floorName.displayName || floorName.name);
          var icon1 = _this.sceneWrapper.icons[type1] || "".concat(type1, ".png");
          var icon2 = _this.sceneWrapper.icons[type2] || "".concat(type2, ".png");

          _this.flatFlyout({
            id: pair[0].nodeId,
            text: textIn,
            image: "".concat(imageBase, "/").concat(icon1),
            position: pair[0].position,
            type: 'transfer-in',
            node: pair[0]
          });

          _this.flatFlyout({
            id: pair[1].nodeId,
            image: "".concat(imageBase, "/").concat(icon2),
            position: pair[1].position,
            type: 'transfer-out',
            node: pair[1]
          });
        });
      }).then(resolve);
    });
  };

  this.animatePath = function (path) {
    return new Promise(function (resolve) {
      var modPath = path.slice(1, -1);
      var sceneWrapper = _this.sceneWrapper,
          meshList = _this.meshList;
      var scene = sceneWrapper.scene,
          nodeSize = sceneWrapper.nodeSize,
          mode = sceneWrapper.mode;
      var parentFloor = path.find(function (p) {
        return p.floor;
      }).floor;
      var parentFloorMesh = scene.getMeshByName(parentFloor);
      var dotOffset = 0.25 * nodeSize;
      var dots = [];
      var newPath = [];
      var dotMaterial = sceneWrapper.getMaterial('node-material', sceneWrapper.nodeColor); // Fill points in the middle

      var oldPt = null;
      var newPt = null; // const pathPosition = scene.getMeshByName(path[0].nodeId).getAbsolutePosition()

      sceneWrapper.log('Creating path', path);

      for (var ptIndex = 0; ptIndex < modPath.length - 1; ptIndex += 1) {
        oldPt = modPath[ptIndex];
        newPt = modPath[ptIndex + 1];
        var oldMesh = mode === 'development' ? scene.getMeshByName(oldPt.nodeId) : scene.getNodeByName(oldPt.nodeId);
        var newMesh = mode === 'development' ? scene.getMeshByName(newPt.nodeId) : scene.getNodeByName(newPt.nodeId);
        var oldMeshP = oldMesh.position;
        var newMeshP = newMesh.position;
        var oldPosition = new BABYLON.Vector3(oldMeshP.x, oldPt.pathY, oldMeshP.z);
        var newPosition = new BABYLON.Vector3(newMeshP.x, newPt.pathY, newMeshP.z);
        var distance = BABYLON.Vector3.Distance(oldPosition, newPosition);
        var discNum = parseInt(distance / dotOffset, 10);
        var subPath = [];

        for (var i = 0; i < discNum; i += 1) {
          subPath.push(BABYLON.Vector3.Lerp(oldPosition, newPosition, i / discNum));
        }

        newPath.push.apply(newPath, subPath);
      }

      var keyFramesX = [];
      var keyFramesZ = [];
      var frameRate = 30;
      newPath.forEach(function (pt, i) {
        dots[i] = BABYLON.MeshBuilder.CreateSphere("pix".concat(i), {
          diameter: 0.2 * nodeSize
        }, scene);
        dots[i].material = dotMaterial;
        dots[i].position.copyFrom(pt);
        dots[i].position.y = pt.y;
        sceneWrapper.log('Created a dot', {
          pt: pt
        });

        if (parentFloorMesh) {
          dots[i].setParent(parentFloorMesh);
        }

        keyFramesX.push({
          frame: i * frameRate,
          value: pt.x
        });
        keyFramesZ.push({
          frame: i * frameRate,
          value: pt.z
        });
      }); // Position X

      var xSlide = new BABYLON.Animation('xSlide', 'position.x', frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE); // Position Z

      var zSlide = new BABYLON.Animation('zSlide', 'position.z', frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
      xSlide.setKeys(keyFramesX);
      zSlide.setKeys(keyFramesZ);
      meshList.push.apply(meshList, dots);
      dots.forEach(function (pt, i) {
        scene.beginDirectAnimation(pt, [xSlide, zSlide], i * frameRate, (i + 1) * frameRate, true, 2.5);
      });
      resolve();
    });
  };

  this.flatFlyout = function (_ref) {
    var id = _ref.id,
        image = _ref.image,
        text = _ref.text,
        type = _ref.type,
        mesh = _ref.mesh;
    var sceneEngine = _this.scene.sceneEngine;

    var finalMesh = mesh || _this.scene.getMeshByName(id) || _this.scene.getNodeByName(id);

    var flyoutPosition = _objectSpread({}, finalMesh.getAbsolutePosition());

    var newRay = new BABYLON.Ray(_this.scene.activeCamera.position, new BABYLON.Vector3(flyoutPosition.x - _this.scene.activeCamera.position.x, flyoutPosition.y - _this.scene.activeCamera.position.y, flyoutPosition.z - _this.scene.activeCamera.position.z));

    var pick = _this.scene.pickWithRay(newRay, function () {
      return true;
    });

    var isVisible = pick && pick.pickedMesh === finalMesh;
    var pos = BABYLON.Vector3.Project(flyoutPosition, BABYLON.Matrix.IdentityReadOnly, _this.scene.getTransformMatrix(), _this.scene.camera.viewport.toGlobal(sceneEngine.getRenderWidth(), sceneEngine.getRenderHeight()));

    if (!pos.hasOwnProperty('_x') || !pos.hasOwnProperty('_y') || isNaN(pos._x) || isNaN(pos._y)) {
      return;
    }

    _this.sceneWrapper.handleFlyout(id, {
      x: Math.round(pos._x),
      y: Math.round(pos._y)
    }, {
      image: image,
      text: text,
      type: type
    }, {
      isVisible: isVisible
    });

    if (finalMesh) {
      if (type === 'floorLabel') {
        finalMesh.isFloor = true;
      }

      _this.sceneWrapper.markerToRenderLoop.push(finalMesh);
    }
  };

  this.sceneWrapper = _sceneWrapper;
  this.scene = _sceneWrapper.scene;
  this.nodeSize = _sceneWrapper.nodeSize;
  this.meshList = _meshList;
  this.markerToRenderLoop = markerToRenderLoop;
};

exports["default"] = PathRenderer;

PathRenderer.getTransferType = function () {
  var types = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var transferOptions = ['elevator', 'escalator', 'stairs', 'ramp'];
  return types.filter(function (t) {
    return transferOptions.includes(t);
  })[0];
};