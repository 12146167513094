"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getFloorFromName;

function getFloorFromName(name) {
  var regex = /([^\|]*?)-(?:[a-z]+?).*/i;
  var match = name.match(regex); // Return the first capture group or nothing

  return match ? match[1] : null;
}